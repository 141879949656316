import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import {AboutUsStoryImage} from '../images/_imagesRootImport'
import { AboutUsText, AboutUsTags } from '../components/layout/strings'
import Ceo from '../images/ceo.jpg'
import Coo from '../images/coo.jpg'
import Designer from '../images/designer.jpg'

import './about-us.css'
import SEO from '../components/seo'

class AboutUs extends Component{
    render(){
        return(
            <Layout selectedPage = "aboutUs">
                <SEO title={AboutUsTags.title}/>
                    <div className="aboutUsImageContainer">
                        <div className="aboutUsHeaderContainer">
                                <h1 className="pageHeader">{AboutUsText.MainHeading}</h1>
                                <p className="mainDescription">{AboutUsText.MainDescription}</p>
                        </div>
                    </div>

                    <div className="section1Container">
                            <img src= {AboutUsStoryImage} className="shadowImage"/>
                            <div className="section1SubContainer">
                                <img src= {AboutUsStoryImage} className="frontShadowImage"/>

                                <div className="rightTextContainer">
                                    <h1 className="sectionHeadingLeftAlign">{AboutUsText.Section1Heading}</h1>
                                    <p className="sectionInfoLeftAlign">{AboutUsText.Section1Description}</p>
                                    <p className="quote" id="sectionQuoteLeftAlign">{AboutUsText.Section1Quote}</p>
                                </div>
                            </div>
                    </div>

                    <div className="section2Container">
                        <div className="dotsViewContainer">
                                <Dots/>
                        </div>
                        <div className="section2SubContainer">
                            <h1 className="mainHeading">{AboutUsText.Section2Heading}</h1>
                            <p id="sectionInfo" className="mainDescription">{AboutUsText.Section2Description}</p>
                        </div>
                    </div>

                    <div className="section3Container">
                            <h1 className="mainHeading">{AboutUsText.Section3Heading}</h1>
                            <div className="foundersImageBlock">
                                <PersonCard image ={Ceo} name={AboutUsText.Ceo} position={AboutUsText.CeoText}/>
                                <PersonCard image ={Designer} name={AboutUsText.Director} position={AboutUsText.DirectorText}/>
                                <PersonCard image ={Coo} name={AboutUsText.Coo} position={AboutUsText.CooText}/>
                            </div>
                    </div>

                    <div className="section4Container">
                        <h1 className="sectionHeading">Our Core values</h1>
                        <div className="coreValueContainer">
                            {
                                AboutUsText.CoreValues.map(data => 
                                {
                                    return <CoreValue data = {data} />
                                })
                            }
                        </div>
                    </div>
            </Layout>
        )
    }
}
    
class PersonCard extends Component {
    render(){
        return (
            <div className = "personContainer">
                <img className="foundersImage" src={this.props.image}/>
    
                <div className="personContainerText">
                    <p className="contactUserName">{this.props.name}</p>  
                    <p className="contactUserPosition">{this.props.position}</p>  
                </div>
            </div>
        );
    }
}

class CoreValue extends Component {
    render(){
        return (
            <div className = "coreValueItemContainer">
                <div className = "coreValueItemSubContainer">
                    <div className="coreValueNumberContainer">
                        <p className="coreValueNumber">{this.props.data.Number}</p>
                    </div>

                    <div>
                        <p className="coreValueHeading">{this.props.data.Heading}</p>
                        <p className="coreValueSubHeading">{this.props.data.Description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

function HandleDotMouseEnter(id)
{
    var dot = document.getElementById("dotViewDot" + id);

    dot.style.transition =  "none";
    dot.style.backgroundColor="slategrey"
    dot.style.boxShadow ="var(--shadow-color-light)";
}

function HandleDotMouseLeave(id)
{
    var dot = document.getElementById("dotViewDot" + id);
    dot.style.transition = "box-shadow, background-color 0.5s linear";
    dot.style.boxShadow ="none";
    dot.style.backgroundColor="var(--input-border-empty)"
}

class Dots extends Component{
    render(){
        const itemCount = [];

        for (var i = 0; i < 1500; i++)
            itemCount.push(1);
        
        var dots = itemCount.map((i, key) => 
        {
            return (
                <div className="dotViewDotContainter" onMouseOver={ e => HandleDotMouseEnter(key)} onMouseOut={ e => HandleDotMouseLeave(key)}>
                    <span id={"dotViewDot" + key} className="dotViewdot"></span>
                </div>
            );   
        });

        return dots;
    }
}

export default AboutUs